import React from "react";

import { Switch, HashRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { userRoutes, authRoutes } from "./routes/allRoutes";

import Authmiddleware from "./routes/middleware/Authmiddleware";

import VerticalLayout from "./components/VerticalLayout/";
//import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

const App = (props) => {
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();

  /*
  Da implementare quando ci sarà la login e i path di login
  const NonAuthmiddleware = ({ component: Component, layout: Layout }) => (
    <Route
      render={(props) => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
    */

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: {
      layoutType: "vertical",
      layoutWidth: "fluid",
      leftSideBarTheme: "dark",
      leftSideBarType: "default",
      topbarTheme: "light",
      isPreloader: false,
      showRightSidebar: false,
      isMobile: false,
      showSidebar: false,
      leftMenu: false,
    },
  };
};

export default connect(mapStateToProps, null)(App);

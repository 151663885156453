import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, Alert, Row, Col, Modal } from "reactstrap";
import TreeViewD3 from "../../components/TreeView/D3/TreeViewD3";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../components/Common/Loader";
import { v4 as uuidv4 } from "uuid";

import "./WhatIfD3.scss";

const data = [
  {
    id: uuidv4(),
    name: "Trasporto di beni e logistica su strada",
    children: [
      {
        id: uuidv4(),
        name: "Ristoranti e ristorazione",
      },
      {
        id: uuidv4(),
        name: "Alimenti freschi e deperibili",
      },
      {
        id: uuidv4(),
        name: "Altri servizi (grafici, ottici, fotografi, ecc.)",
      },
      {
        id: uuidv4(),
        name: "Trasporto pubblico e privato di passeggeri su strada",
        children: [
          {
            id: uuidv4(),
            name: "Manpower (disponibilità di manodopera e della popolazione alle proprie mansioni)",
            children: [
              {
                id: uuidv4(),
                name: "Difesa civile",
              },
              {
                id: uuidv4(),
                name: "Bibite (inclusa acqua imbottigliata)",
              },
              {
                id: uuidv4(),
                name: "Istituzioni politiche (nazionali, regionali, locali)",
              },
              {
                id: uuidv4(),
                name: "Ordine pubblico e safety",
              },
              {
                id: uuidv4(),
                name: "Informatica e filiera",
              },
              {
                id: uuidv4(),
                name: "Protezione civile",
              },
              {
                id: uuidv4(),
                name: "Religione, organizzazioni religiose",
              },
              {
                id: uuidv4(),
                name: "Difesa",
              },
              {
                id: uuidv4(),
                name: "Amministrazioni pubbliche e servizi del cittadino",
              },
            ],
          },
          {
            id: uuidv4(),
            name: "Servizi di manutenzione (real estate)",
          },
          {
            id: uuidv4(),
            name: "Associazionismo",
          },
          {
            id: uuidv4(),
            name: "Commercio al dettaglio",
          },
        ],
      },
      {
        id: uuidv4(),
        name: "Publishing (libri, periodici e giornali)",
      },
      {
        id: uuidv4(),
        name: "Acque reflue",
      },
      {
        id: uuidv4(),
        name: "Alimenti surgelati",
      },
      {
        id: uuidv4(),
        name: "Servizi postali",
      },
      {
        id: uuidv4(),
        name: "Contante",
      },
    ],
  },
];

const WhatIfD3 = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [horizontal, setHorizontal] = useState(true);
  const [show, setShow] = useState(true);
  const [showDetail, setShowDetail] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function handleChangeOrientation() {
    setHorizontal(!horizontal);
  }

  function handleChangeDetail() {
    setShowDetail(!showDetail);
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setShow(true);
    }, 1000);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content whatif">
        <Container fluid>
          <Breadcrumbs title={"Analisi WhatIf: Elettricità"} />
          {loading ? (
            <Loader className="w-100 p-5" />
          ) : error ? (
            <CardBody>
              <Alert transition={true} className="mb-0" color="danger">
                {error}
              </Alert>
            </CardBody>
          ) : (
            <>
              <Row>
                <Col sm="12">
                  <div style={{ display: "flex" }}>
                    <div
                      className="custom-control custom-switch mt-1 ml-2 mr-3 mb-6"
                      dir="ltr"
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch1"
                        checked={!horizontal}
                        onChange={handleChangeOrientation}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customSwitch1"
                      >
                        {horizontal
                          ? "Abilita visualizzazione verticale"
                          : "Abilita visualizzazione verticale"}
                      </label>
                    </div>
                    <div
                      className="custom-control custom-switch mt-1 ml-2 mr-3 mb-6"
                      dir="ltr"
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch2"
                        checked={showDetail}
                        onChange={handleChangeDetail}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customSwitch2"
                      >
                        {showDetail
                          ? "Abilita visualizzazione dettagli"
                          : "Abilita visualizzazione dettagli"}
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Card className=" mt-3">
                <CardBody>
                  <TreeViewD3
                    showDetail={showDetail}
                    horizontal={horizontal}
                    data={data}
                  ></TreeViewD3>
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WhatIfD3;

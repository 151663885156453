import React from "react";
import { Redirect } from "react-router-dom";
import Analisi from "../containers/Analisi/Analisi";
import WhatIf from "../containers/WhatIf/WhatIf";
import WhatIfD3 from "../containers/WhatIfD3/WhatIfD3";

const userRoutes = [
  { path: "/analisi/", component: Analisi },
  { path: "/what-if/", component: WhatIf },
  { path: "/what-if-d3/", component: WhatIfD3 },
  { path: "/", exact: true, component: () => <Redirect to="/analisi" /> },
];

//routes di autenticazione
const authRoutes = [];

export { userRoutes, authRoutes };

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Alert,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  Fade,
} from "reactstrap";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../components/Common/Loader";
import 'animate.css';
import './Analisi.scss';
import logoAnalisi from '../../assets/images/u56.svg'

const Analisi = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [dipendences, setDipendences] = useState([
    {
      name: "Operatori",
    },
    {
      name: "Dipendenze",
    },
    {
      name: "What If",
    },
  ]);
  const [options, setOptions] = useState([
    {
      name: "Operatori",
    },
    {
      name: "Servizi",
    },
  ]);
  const [services, SetServices] = useState([
    {
      name: "Elettricità",
    },
    {
      name: "Trasporto di beni e logistica su strada",
    },
  ]);
  const [selectedDipendences, setSelectedDipendences] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  var flag = ((Array.isArray(selectedDipendences) && selectedDipendences.length > 0) || selectedDipendences.value !== undefined);
  var flagOptions = ((Array.isArray(selectedOptions) && selectedOptions.length > 0) || selectedOptions.value !== undefined);

  useEffect(() => {
    formatValuesForInputs();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const repeatAnimate = (type) => {
    let analizza = document.getElementById('analizza')
    if (type === 'dipendences' && !flag) {
      analizza.classList.add('animate__slideInDown')
      analizza.classList.add('animate__fast')
      setTimeout(() => {
        analizza.classList.remove('animate__animated');
        analizza.classList.remove('animate__slideInDown');
        analizza.classList.remove('animate__fast')
      }, 1000)
    } else if (type === 'options' && !flagOptions) {
      analizza.classList.add('animate__animated');
      analizza.classList.add('animate__slideInDown');
      analizza.classList.add('animate__fast')
    }
  }

  /**
   * I valori degli input devono essere formattati in un array di oggetti così definiti:
   * object { value: 'Valore', label: 'Titolo della label'}
   */
  function formatValuesForInputs() {
    const optionsFormatted = options.map((c) => {
      return {
        value: c.name,
        label: c.name,
      };
    });
    setOptions(optionsFormatted);
    const dipencedesFormatted = dipendences.map((c) => {
      return {
        value: c.name,
        label: c.name,
      };
    });
    setDipendences(dipencedesFormatted);
    const servicesFormatted = services.map((c) => {
      return {
        value: c.name,
        label: c.name,
      };
    });
    SetServices(servicesFormatted);
  }

  function handleOnSubmit(event) {
    event.preventDefault();
    if (selectedServices.value === "Elettricità") {
      props.history.push("what-if-d3");
    } else {
      props.history.push("what-if");
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumbs title={"Analisi"} breadcrumbItem={""} />
          {loading ? (
            <Loader className="w-100 p-5" />
          ) : error ? (
            <CardBody>
              <Alert transition={true} className="mb-0" color="danger">
                {error}
              </Alert>
            </CardBody>
          ) : (
            <React.Fragment>
              <Row style={{justifyContent:'center'}}>
                <div className='analisiContainer'>
                    <img className='imgAnalisi' src={logoAnalisi} alt='logoAnalisi'></img>
                    <span className='analisiFont'> Analisi</span>
                </div>
              </Row>
              <Card style={{marginTop:'-2rem'}}>
                <CardBody style={{marginTop:'1rem'}}>
                  <CardTitle>Tipo di analisi</CardTitle>
                  <CardSubtitle className="mb-3">
                    Seleziona la tipologia di analisi e le opzioni che vuoi
                    visualizzare
                  </CardSubtitle>
                  <Form onSubmit={handleOnSubmit}>
                    <Row>
                      <Col md={{ size: 6, offset: 3 }}>
                        <FormGroup className="select2-container">
                          <Label className="control-label">
                            Tipologia di analisi
                          </Label>
                          <Select
                            name="tipologies"
                            classNamePrefix="select2-selection"
                            defaultValue={[]}
                            placeholder="Inserisci la tipologia"
                            title="Tipologies"
                            options={dipendences}
                            isMulti={false}
                            required
                            values={selectedDipendences}
                            onChange={(value) => { repeatAnimate('dipendences'); setSelectedDipendences(value); }}
                          />
                        </FormGroup>
                      </Col>
                      <Col className={!flag ? 'd-none' : 'd-block '} md={{ size: 6, offset: 3 }}>
                        <FormGroup className="select2-container">
                          <Label className="control-label">Opzioni</Label>
                          <Select
                            name="options"
                            classNamePrefix="select2-selection"
                            placeholder="Inserisci le opzioni"
                            title="Options"
                            options={options}
                            isMulti={false}
                            required
                            value={selectedOptions}
                            onChange={(value) => { repeatAnimate('options'); setSelectedOptions(value); }}
                          />
                        </FormGroup>
                      </Col>
                      <Col className={!flagOptions ? 'd-none' : 'd-block'} md={{ size: 6, offset: 3 }}>
                        <FormGroup className="select2-container">
                          <Label className="control-label">Servizi</Label>
                          <Select
                            name="services"
                            classNamePrefix="select2-selection"
                            placeholder="Inserisci il servizio"
                            title="Services"
                            options={services}
                            isMulti={false}
                            required
                            value={selectedServices}
                            onChange={(value) => setSelectedServices(value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col id='analizza' style={{ animationFillMode: 'none' }} className={'animate__animated'} md={{ size: 6, offset: 3 }}>
                        <FormGroup className="select2-container">
                          <Button
                            type="submit"
                            color="primary"
                            className="waves-effect waves-light mt-4 px-4 w-100"
                            onClick={handleOnSubmit}
                          >
                            Analizza
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </React.Fragment>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Analisi;

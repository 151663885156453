import React, { useState } from "react";
import Tree from "react-d3-tree";
import {
  useCenteredTreeHorizontal,
  useCenteredTreeVertical,
} from "./HelpersD3";
import "./TreeViewD3.scss";

const containerStyles = {
  width: "100%",
  height: "80vh",
};
const orgChart = {
  name: "Elettricità",
  attributes: {
    periodo: "0",
  },
  children: [
    {
      name: "Servizi di Emergenza Sanitaria",
      attributes: {
        dipartimenti: "Pronto soccorso",
        periodo: "1",
      },
      children: [
        {
          name: "Protezione Civile",
          attributes: {
            periodo: "2",
            perdita: "12mln",
          },
        },
        {
          name: "Difesa civile",
          attributes: {
            periodo: "2",
          },
        },
      ],
    },
    {
      name: "Trasporto ferroviario di beni e logistica",
      attributes: {
        periodo: "1",
      },
    },
    {
      name: "Trasporto ferroviario passeggeri",
      attributes: {
        periodo: "1",
      },
    },
    {
      name: "Servizi generali",
      attributes: {
        periodo: "1",
      },
      children: [
        {
          name: "Servizi di Pagamento",
          attributes: {
            periodo: "2",
          },
        },
        {
          name: "Informatica e filiera",
          attributes: {
            periodo: "3",
          },
          children: [
            {
              name: "Software",
              attributes: {
                periodo: "4",
              },
            },
            {
              name: "Piattaforme di controllo",
              attributes: {
                periodo: "5",
              },
            },
          ],
        },
      ],
    },
  ],
};
const nodeSizeVertical = { x: 300, y: 200 };
const nodeSizeHorizontal = { x: 350, y: 140 };

const TreeViewD3 = (props) => {
  const [translateVertical, containerRefVertical] = useCenteredTreeVertical();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState();
  const foreignObjectProps = {
    width: nodeSizeVertical.x,
    height: nodeSizeHorizontal.y,
    x: -110,
    y: 15,
  };

  const [translateHorizontal, containerRefHorizontal] =
    useCenteredTreeHorizontal();

  const getDynamicPathClass = ({ source, target }, orientation) => {
    if (!target.children) {
      // Target node has no children -> this link leads to a leaf node.
      return "link__to-leaf";
    }

    // Style it as a link connecting two branch nodes by default.
    return "link__to-branch";
  };

  function onNodeClick(node) {
    if (!modalOpen) {
      setModalOpen(true);
      setSelectedNode(node);
    } else {
      if (node) {
        setModalOpen(false);
        setTimeout(() => {
          setModalOpen(true);
          setSelectedNode(node);
        }, 400);
      } else {
        setModalOpen(false);
        setSelectedNode(null);
      }
    }
  }

  const renderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    foreignObjectProps,
  }) => (
    <g
      className={
        nodeDatum.children && nodeDatum.children.length > 0
          ? "rd3t-node node__branch"
          : "rd3t-node node__leaf"
      }
    >
      <circle onClick={toggleNode} r={15}></circle>

      {/* `foreignObject` requires width & height to be explicitly set. */}
      <foreignObject {...foreignObjectProps}>
        <g>
          <circle onClick={toggleNode} r="15"></circle>
          <div className="text__container">
            <span onClick={toggleNode} className="rd3t-label__title">
              {nodeDatum.name}
            </span>
            <br></br>

            {nodeDatum.attributes && nodeDatum.attributes.periodo && (
              <span className="rd3t-label__attributes">
                Periodo : {nodeDatum.attributes.periodo}
                <br></br>
              </span>
            )}
            <span
              className={
                props.showDetail
                  ? "show-card text__detail"
                  : "hide-card text__detail"
              }
              onClick={() => onNodeClick(nodeDatum)}
            >
              Visualizza dettagli
            </span>
          </div>
        </g>
      </foreignObject>
    </g>
  );

  return (
    <>
      <div className={props.showDetail ? "show-card" : "hide-card"}>
        <div className={!modalOpen ? "nodeCard closed" : "nodeCard open"}>
          <div className="nodeCard__header">
            {selectedNode && selectedNode && selectedNode.name}
          </div>
          <div className="nodeCard__body">
            <span className="attribute">
              Periodo:{" "}
              {selectedNode &&
                selectedNode.attributes &&
                selectedNode.attributes.periodo}{" "}
            </span>
            <br></br>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </div>
          <div className="nodeCard__footer">
            <span className="hours">
              Aggiornamento dati: {new Date().toLocaleDateString()} - 15:30
            </span>
            <span onClick={() => setModalOpen(false)} className="btn-close">
              Chiudi scheda
            </span>
          </div>
        </div>
      </div>

      <div
        id="treeWrapper"
        style={containerStyles}
        ref={props.horizontal ? containerRefHorizontal : containerRefVertical}
      >
        <Tree
          nodeSize={props.horizontal ? nodeSizeHorizontal : nodeSizeVertical}
          orientation={props.horizontal ? "horizontal" : "vertical"}
          initialDepth={100}
          translate={props.horizontal ? translateHorizontal : translateVertical}
          data={orgChart}
          rootNodeClassName="node__root"
          branchNodeClassName="node__branch"
          leafNodeClassName="node__leaf"
          pathFunc={"step"}
          pathClassFunc={getDynamicPathClass}
          onNodeClick={onNodeClick}
          renderCustomNodeElement={(rd3tProps) =>
            renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
          }
        />
      </div>
    </>
  );
};

export default TreeViewD3;

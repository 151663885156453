import React from 'react';
import { Spinner } from 'reactstrap';
const Loader = (props) => {
	return (
		<div className={"d-flex justify-content-center " + props.className}>
			<Spinner className="mr-2" color="primary" size={props.size}/>
		</div>
	);
};
export default Loader;

import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = (props) => {
  return (
    <React.Fragment>
      <footer className="footer footer-full">
        <Container fluid={true}>
          <Row>
            <Col md={6}> Iside &#174; </Col>
            <Col md={6}>
              <div className="float-sm-right">
                Tutti i diritti riservati © {new Date().getFullYear()}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;

import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Row, Col, BreadcrumbItem, Button } from "reactstrap";

const Breadcrumb = (props) => {
  function handleOnBackClick() {
    props.history.goBack();
  }

  return (
    <>
      {props.linkButton ? (
        <Row>
          <Col xs="6">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <div>
                {props.canGoBack && (
                  <Button
                    color="link"
                    className="back-link"
                    onClick={handleOnBackClick}
                  >
                    <i className="bx bx-arrow-back"></i>&nbsp;
                  </Button>
                )}
                {props.title && (
                  <h4 className="d-inline mb-0 font-size-18">{props.title}</h4>
                )}
              </div>
            </div>
          </Col>
          {props.linkButton && (
            <Col xs="6">
              <div className="float-sm-right d-flex mt-negative">
                <Link to={props.linkButton} className="text-dark">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded waves-effect waves-light mb-2 mr-2"
                  >
                    <i className="mdi mdi-plus mr-1"></i>
                    {props.messageButton}
                  </Button>
                </Link>
              </div>
            </Col>
          )}
        </Row>
      ) : (
        <Row>
          <Col xs="12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <div>
                {props.canGoBack && (
                  <Button
                    color="link"
                    className="back-link"
                    onClick={handleOnBackClick}
                  >
                    <i className="bx bx-arrow-back"></i>&nbsp;
                  </Button>
                )}
                <h4 className="d-inline mb-0 font-size-18">{props.title}</h4>
              </div>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem active>
                    <Link to="#">{props.breadcrumbItem}</Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default withRouter(Breadcrumb);

import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, Alert, Row, Col } from "reactstrap";
import TreeView from "../../components/TreeView/TreeView";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../components/Common/Loader";
import { v4 as uuidv4 } from "uuid";

import "./WhatIf.scss";

const data = [
  {
    id: uuidv4(),
    name: "Trasporto di beni e logistica su strada",
    children: [
      {
        id: uuidv4(),
        name: "Ristoranti e ristorazione",
      },
      {
        id: uuidv4(),
        name: "Alimenti freschi e deperibili",
      },
      {
        id: uuidv4(),
        name: "Altri servizi (grafici, ottici, fotografi, ecc.)",
      },
      {
        id: uuidv4(),
        name: "Trasporto pubblico e privato di passeggeri su strada",
        children: [
          {
            id: uuidv4(),
            name: "Manpower (disponibilità di manodopera e della popolazione alle proprie mansioni)",
            children: [
              {
                id: uuidv4(),
                name: "Difesa civile",
              },
              {
                id: uuidv4(),
                name: "Bibite (inclusa acqua imbottigliata)",
              },
              {
                id: uuidv4(),
                name: "Istituzioni politiche (nazionali, regionali, locali)",
              },
              {
                id: uuidv4(),
                name: "Ordine pubblico e safety",
              },
              {
                id: uuidv4(),
                name: "Informatica e filiera",
              },
              {
                id: uuidv4(),
                name: "Protezione civile",
              },
              {
                id: uuidv4(),
                name: "Religione, organizzazioni religiose",
              },
              {
                id: uuidv4(),
                name: "Difesa",
              },
              {
                id: uuidv4(),
                name: "Amministrazioni pubbliche e servizi del cittadino",
              },
            ],
          },
          {
            id: uuidv4(),
            name: "Servizi di manutenzione (real estate)",
          },
          {
            id: uuidv4(),
            name: "Associazionismo",
          },
          {
            id: uuidv4(),
            name: "Commercio al dettaglio",
          },
        ],
      },
      {
        id: uuidv4(),
        name: "Publishing (libri, periodici e giornali)",
      },
      {
        id: uuidv4(),
        name: "Acque reflue",
      },
      {
        id: uuidv4(),
        name: "Alimenti surgelati",
      },
      {
        id: uuidv4(),
        name: "Servizi postali",
      },
      {
        id: uuidv4(),
        name: "Contante",
      },
    ],
  },
];

const WhatIf = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content whatif">
        <Container fluid>
          <Breadcrumbs
            title={
              "Analisi WhatIf: Servizio trasporto di beni e logistica su strada"
            }
          />
          <h5 className="periodo-title">Periodo:</h5>
          {loading ? (
            <Loader className="w-100 p-5" />
          ) : error ? (
            <CardBody>
              <Alert transition={true} className="mb-0" color="danger">
                {error}
              </Alert>
            </CardBody>
          ) : (
            <>
              <Row>
                <Col md="3">
                  <Card className="period">
                    <CardBody>
                      <span>t0</span>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="period">
                    <CardBody>
                      <span>t1</span>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="period">
                    <CardBody>
                      <span>t2</span>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="period">
                    <CardBody>
                      <span>tn</span>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <TreeView data={data}></TreeView>
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WhatIf;

import React, { useState } from "react";

import "./TreeView.scss";

const TreeItem = (props) => {
  return (
    <>
      <div
        key={props.data && props.data.id + "-" + props.data.name}
        className={"branch lvl" + props.level}
      >
        {props.data &&
          props.data.map((item) => (
            <>
              <div className="entry">
                <span className="label">{item.name}</span>
                {item.children && (
                  <TreeItem
                    level={props.level && props.level + 1}
                    data={item.children}
                  />
                )}
              </div>
            </>
          ))}
      </div>
    </>
  );
};

export default TreeItem;

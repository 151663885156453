import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    /*
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setUser(obj);
    }
    */
    const fakeUser = { firstname: "Mario", lastname: "Rossi" };
    setUser(fakeUser);
  }, [props.success]);

  return (
    <React.Fragment>
      {user && (
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item waves-effect d-flex align-items-center"
            id="page-header-user-dropdown"
            tag="button"
          >
            <span className="p-2 rounded-circle header-profile-user">
              {user && user.firstname.charAt(0) + user.lastname.charAt(0)}
            </span>

            <span className="d-none d-xl-inline-block ml-2 mr-1">
              {user ? user.firstname : "..."}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem
              tag="a"
              href={"/user/" + (user ? user._id : "")} //href="/profile">
            >
              {" "}
              <i className="bx bx-user font-size-16 align-middle mr-1"></i>
              Profilo
            </DropdownItem>
            <div className="dropdown-divider"></div>
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
              <span>Logout</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      )}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error, success } = state.User;
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, null)(ProfileMenu));

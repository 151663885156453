import { LOAD_USER } from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  user: null,
  loading: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER:
      state = { ...state, loading: true };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default user;

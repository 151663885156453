import React, { useState, useEffect } from "react";
import TreeItem from "../TreeView/TreeItem";

import "./TreeView.scss";

const TreeView = (props) => {
  const [expanded, setExpanded] = useState(false);

  function handleClick(item) {
    //  setExpanded(!expanded)
  }

  return (
    <div id="wrapper" key={props.data && props.data.id}>
      {props.data &&
        props.data.map((item) => (
          <div key={item.id}>
            <span id="root" className="label">
              {item.name}
            </span>
            {item.children && (
              <TreeItem level={1} expanded={true} data={item.children} />
            )}
          </div>
        ))}
    </div>
  );
};

export default TreeView;
